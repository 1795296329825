import "./Cherry.css";

function Cherry() {
  return (
    <div className="cherry">
      <svg
        viewBox="0 0 2800 2800"
        height="100%"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="leaf-bg"
          d="M2282.67 186.769L2282.4 186.798C2210.93 207.986 2153.52 228.758 2130.82 265.547C2108.02 302.455 2117.42 360.521 2133.37 431.691C2133.46 432.107 2133.82 432.405 2134.26 432.405C2134.29 432.405 2134.32 432.405 2134.35 432.405C2134.41 432.375 2134.47 432.375 2134.53 432.345L2134.5 432.375C2189.77 416.298 2233.93 400.489 2256.61 364.176C2280.53 325.812 2283.14 261.803 2283.62 187.69C2283.62 187.452 2283.53 187.214 2283.35 187.036C2283.17 186.858 2282.94 186.769 2282.7 186.769L2282.67 186.769"
        />
        <path
          className="leaf-bg"
          d="M1642.78 52.8071C1642.28 52.8071 1641.86 53.2231 1641.86 53.7283C1641.57 144.72 1646.41 224.806 1694.61 281.415C1745.01 340.611 1844.62 377.221 1955.91 413.891C1956 413.921 1956.08 413.921 1956.2 413.921C1956.38 413.921 1956.56 413.861 1956.74 413.772C1956.98 413.594 1957.12 413.297 1957.12 413L1957.12 413L1957.1 412.911C1945.8 304.505 1931.66 207.956 1882.03 150.604C1832.17 92.9837 1743.64 72.0633 1642.99 52.8071L1642.81 52.8071L1642.78 52.8071"
        />
        <path
          className="cherry-bg"
          d="M1955.85 1611.4C1639.93 1611.4 1383.81 1867.53 1383.81 2183.44C1383.81 2499.39 1639.93 2755.48 1955.85 2755.48C2271.79 2755.48 2527.92 2499.39 2527.92 2183.44C2527.92 1943.9 2380.67 1738.77 2171.77 1653.57L2171.56 1653.51L2171.53 1653.51C2171.47 1653.48 2171.44 1653.48 2171.41 1653.48C2171.02 1653.48 2170.7 1653.72 2170.55 1654.08C2135.72 1740.61 2094.65 1825.18 2054 1908.92L2052.9 1911.21C2052.43 1912.16 2051.92 1913.05 2051.36 1913.89C2051.18 1914.18 2051.15 1914.54 2051.33 1914.84C2051.47 1915.1 2051.71 1915.28 2052.01 1915.31L2051.98 1915.31C2090.4 1929.67 2135.69 1934.42 2184.87 1939.35C2196.85 1940.54 2205.58 1951.24 2204.39 1963.22C2203.27 1974.45 2193.79 1982.83 2182.73 1982.83C2181.99 1982.83 2181.31 1982.8 2180.53 1982.71L2180.53 1982.71L2178.13 1982.47L2175.69 1982.23L2175.69 1982.23C2119.2 1976.59 2057.69 1970.11 2006.96 1942.68C1955.46 1914.84 1916.83 1866.34 1881.59 1822.12L1880.28 1820.46L1880.28 1820.46L1880.28 1820.46L1880.07 1820.19L1880.07 1820.19C1872.58 1810.8 1874.13 1797.07 1883.55 1789.58C1887.56 1786.37 1892.34 1784.83 1897.1 1784.83C1903.52 1784.83 1909.88 1787.65 1914.15 1793.06L1914.36 1793.3C1914.39 1793.36 1914.45 1793.42 1914.54 1793.48L1914.48 1793.48C1946.58 1833.74 1976.14 1870.38 2011.51 1894.48C2011.68 1894.57 2011.86 1894.63 2012.04 1894.63C2012.19 1894.63 2012.34 1894.6 2012.46 1894.54C2012.66 1894.42 2012.81 1894.24 2012.9 1894.04L2012.9 1894.04C2013.11 1893.41 2013.38 1892.79 2013.68 1892.19C2054.71 1807.68 2095.25 1724 2129.36 1639.57L2129.33 1639.57C2129.48 1639.43 2129.57 1639.22 2129.6 1639.01C2129.63 1638.59 2129.36 1638.18 2128.95 1638.06C2074.36 1620.73 2016.2 1611.4 1955.85 1611.4"
        />
        <path
          className="cherry-bg"
          d="M844.144 1188.18C528.211 1188.18 272.094 1444.31 272.094 1760.25C272.094 2076.17 528.211 2332.29 844.144 2332.29C1160.08 2332.29 1416.2 2076.17 1416.2 1760.25C1416.2 1607.12 1356.05 1468.08 1258.08 1365.41L1257.6 1365.14L1257.57 1365.14L1257.07 1365.2C1174.36 1396.64 1088.93 1425.23 1003.99 1453.43C1003.56 1453.58 1003.3 1454 1003.37 1454.44C1003.43 1454.83 1003.72 1455.12 1004.09 1455.18L1004.06 1455.21C1045.06 1473.76 1094.97 1479.02 1149.71 1484.48C1161.68 1485.7 1170.42 1496.37 1169.22 1508.35C1168.09 1519.58 1158.62 1527.96 1147.56 1527.96C1146.83 1527.96 1146.11 1527.93 1145.37 1527.87L1142.93 1527.6L1140.48 1527.37C1084.01 1521.72 1022.49 1515.24 971.794 1487.81C920.295 1459.97 881.649 1411.47 846.423 1367.25L845.098 1365.59L845.092 1365.59L844.902 1365.35L844.902 1365.35C837.404 1355.93 838.956 1342.23 848.37 1334.71C852.382 1331.53 857.172 1329.99 861.932 1329.99C868.342 1329.99 874.687 1332.78 878.99 1338.19L878.99 1338.19L879.174 1338.43C879.221 1338.49 879.272 1338.55 879.328 1338.58L879.302 1338.58C906.183 1372.33 931.293 1403.51 959.506 1426.89C959.676 1427.04 959.884 1427.1 960.092 1427.1C960.225 1427.1 960.359 1427.1 960.484 1427.04C960.641 1426.95 960.772 1426.83 960.861 1426.69L960.858 1426.72C963.459 1422.76 967.339 1419.67 972.151 1418.07C1056.68 1390.02 1140.55 1362.14 1221.29 1332.07L1221.28 1332.07C1221.61 1331.98 1221.88 1331.74 1221.97 1331.42C1222.07 1331.06 1221.96 1330.7 1221.69 1330.46C1120.96 1241.91 988.819 1188.18 844.144 1188.18"
        />
        <path
          className="cherry-border"
          d="M1627.54 4.60709L1627.54 4.60709C1732.47 24.2794 1849.61 45.17 1915.67 121.482L1915.67 121.482C1981.2 197.169 1992.96 323.88 2004.49 438.912C2005.74 451.393 1996.62 462.537 1984.17 463.785C1971.69 465.033 1960.54 455.94 1959.29 443.459L1959.29 443.459C1947.2 322.692 1935.31 213.573 1881.32 151.198L1881.32 151.198C1827.86 89.4177 1729.65 69.9535 1619.19 49.2411C1606.86 46.953 1598.72 35.0664 1601.03 22.7638C1603.35 10.4315 1615.21 2.28921 1627.54 4.60709"
        />
        <path
          className="cherry-border"
          d="M1972.46 466.103L1972.46 466.103C1854.28 427.62 1725.72 386.612 1660.73 310.27L1660.73 310.27C1594.32 232.264 1596.58 121.214 1597.62 22.4666C1597.76 9.92632 1608.02-0.117821 1620.56 0.00104422C1633.1 0.149626 1643.17 10.4018 1643.02 22.9421L1643.02 22.9421C1641.92 126.742 1642.01 218.238 1695.32 280.821L1695.32 280.821C1750.03 345.098 1863.07 382.719 1986.51 422.925C1998.43 426.818 2004.97 439.626 2001.08 451.542C1997.18 463.488 1984.37 469.996 1972.46 466.103"
        />
        <path
          className="cherry-border"
          d="M2328.19 170.306L2328.19 170.306C2327.93 246.261 2328.43 333.092 2294.35 387.741L2294.35 387.741C2258.95 444.44 2189.45 463.042 2132.51 479.208C2120.45 482.655 2107.91 475.642 2104.46 463.577C2101.04 451.512 2108.02 438.972 2120.09 435.525L2120.09 435.525C2182.08 417.903 2231.5 402.688 2255.8 363.7L2255.8 363.7C2281.45 322.602 2282.49 251.818 2282.79 170.127C2282.82 157.587 2293.01 147.454 2305.55 147.513C2318.12 147.543 2328.22 157.736 2328.19 170.306"
        />
        <path
          className="cherry-border"
          d="M2094.83 462.804L2094.83 462.804L2094.68 462.151L2094.68 462.151C2077.92 389.583 2056.2 301.682 2092.96 242.13L2092.96 242.13C2129.48 183.024 2220.17 158.36 2295.54 136.637C2307.6 133.16 2320.17 140.114 2323.65 152.149C2327.12 164.214 2320.17 176.784 2308.11 180.261L2308.11 180.261C2224.75 204.301 2156.7 225.4 2131.59 266.022L2131.59 266.022C2106.93 305.961 2120.3 371.218 2138.93 451.958L2138.93 451.958L2139.08 452.612C2141.9 464.825 2134.26 477.009 2122.05 479.832C2109.84 482.655 2097.65 475.048 2094.83 462.804"
        />
        <path
          className="cherry-border"
          d="M2073.7 502.387L2073.7 502.387L2074.09 503.456L2074.09 503.456C2169.42 754.887 2267.07 1011.82 2263.8 1248.57L2263.8 1248.57C2260.53 1486.39 2155.6 1701.74 2053.7 1911.63C2048.24 1922.92 2034.65 1927.62 2023.36 1922.15C2012.1 1916.65 2007.38 1903.07 2012.84 1891.81L2012.84 1891.81C2115.6 1680.11 2215.27 1473.91 2218.39 1247.94L2218.39 1247.94C2221.51 1021.59 2127.88 773.371 2031.62 519.563L2031.62 519.563L2031.24 518.493C2026.78 506.785 2032.69 493.65 2044.4 489.222C2056.14 484.765 2069.24 490.678 2073.7 502.387"
        />
        <path
          className="cherry-border"
          d="M971.862 1417.21L971.862 1417.21C1230.45 1331.39 1482.97 1247.23 1657.64 1096.06L1657.64 1096.06C1831.93 945.191 1930.26 726.122 2030.91 501.049C2036.02 489.609 2049.45 484.468 2060.89 489.579C2072.36 494.69 2077.48 508.122 2072.36 519.592L2072.36 519.592C1972.64 742.674 1870.62 971.757 1687.36 1130.38L1687.36 1130.38C1504.48 1288.68 1242.29 1375.31 986.165 1460.3C974.26 1464.25 961.411 1457.8 957.459 1445.91C953.509 1434 959.958 1421.16 971.862 1417.21"
        />
        <path
          className="cherry-border"
          d="M1914.9 1792.47L1914.9 1792.47C1951.89 1838.91 1985.36 1880.43 2028.15 1903.55L2028.15 1903.55C2070.97 1926.69 2124.93 1932.43 2184.96 1938.43C2197.44 1939.68 2206.56 1950.82 2205.32 1963.3C2204.07 1975.79 2192.92 1984.88 2180.44 1983.63L2180.44 1983.63C2122.7 1977.87 2058.9 1971.8 2006.54 1943.48L2006.54 1943.48C1954.09 1915.13 1914.96 1865.48 1879.36 1820.78C1871.54 1810.98 1873.18 1796.68 1882.98 1788.87C1892.79 1781.05 1907.08 1782.66 1914.9 1792.47"
        />
        <path
          className="cherry-border"
          d="M879.709 1337.63L879.709 1337.63C916.709 1384.07 950.19 1425.56 992.958 1448.68L992.958 1448.68C1035.79 1471.83 1089.77 1477.56 1149.8 1483.59C1162.28 1484.84 1171.38 1495.95 1170.13 1508.44C1168.89 1520.92 1157.76 1530.01 1145.28 1528.76L1145.28 1528.76C1087.53 1523 1023.72 1516.93 971.357 1488.61L971.357 1488.61C918.926 1460.3 879.795 1410.61 844.183 1365.92C836.37 1356.11 837.987 1341.82 847.796 1334C857.606 1326.18 871.893 1327.79 879.709 1337.63"
        />
        <path
          className="cherry-border"
          d="M2527 2183.44L2527 2183.44C2527 1868.03 2271.29 1612.32 1955.85 1612.32L1955.85 1612.32C1640.44 1612.32 1384.73 1868.03 1384.73 2183.44L1384.73 2183.44C1384.73 2498.88 1640.44 2754.59 1955.85 2754.59L1955.85 2754.59C2271.29 2754.59 2527 2498.88 2527 2183.44ZM2572.4 2183.44L2572.4 2183.44C2572.4 2523.96 2296.37 2800 1955.85 2800L1955.85 2800C1615.36 2800 1339.32 2523.96 1339.32 2183.44L1339.32 2183.44C1339.32 1842.95 1615.36 1566.92 1955.85 1566.92L1955.85 1566.92C2296.37 1566.92 2572.4 1842.95 2572.4 2183.44L2572.4 2183.44"
        />
        <path
          className="cherry-border"
          d="M1415.28 1760.25L1415.28 1760.25C1415.28 1444.81 1159.57 1189.1 844.144 1189.1L844.144 1189.1C528.716 1189.1 273.016 1444.81 273.016 1760.25L273.016 1760.25C273.016 2075.66 528.716 2331.37 844.144 2331.37L844.144 2331.37C1159.57 2331.37 1415.28 2075.66 1415.28 1760.25ZM1460.68 1760.25L1460.68 1760.25C1460.68 2100.74 1184.65 2376.78 844.144 2376.78L844.144 2376.78C503.633 2376.78 227.597 2100.74 227.597 1760.25L227.597 1760.25C227.597 1419.73 503.633 1143.7 844.144 1143.7L844.144 1143.7C1184.65 1143.7 1460.68 1419.73 1460.68 1760.25L1460.68 1760.25"
        />
        <path
          className="cherry-shine"
          d="M2023.81 2676.02L2024.73 2675.9L2024.73 2675.9L2023.84 2676.02C2052.69 2671.24 2077.65 2666.93 2098.13 2657.54L2098.13 2657.54C2118.34 2648.3 2135.3 2633.59 2155.1 2616.11C2164.49 2607.82 2178.84 2608.75 2187.13 2618.17C2195.42 2627.56 2194.53 2641.91 2185.11 2650.2L2185.11 2650.2C2166.18 2666.9 2144.34 2686.33 2117.03 2698.85L2117.03 2698.85C2090.02 2711.21 2058.7 2716.29 2031.27 2720.84L2030.38 2720.95L2030.35 2720.98C2018.91 2722.89 2007.2 2714.54 2005.15 2702.17C2003.07 2689.78 2011.45 2678.1 2023.81 2676.02"
        />
        <path
          className="cherry-shine"
          d="M958.092 2248.14L959.084 2247.99L959.102 2247.99L958.11 2248.14C986.964 2243.35 1011.92 2239.04 1032.42 2229.65L1032.42 2229.65C1052.63 2220.41 1069.57 2205.67 1089.37 2188.23C1098.78 2179.94 1113.13 2180.86 1121.42 2190.25C1129.71 2199.67 1128.81 2214.02 1119.4 2222.31L1119.4 2222.31C1100.45 2239.01 1078.63 2258.45 1051.32 2270.96L1051.32 2270.96C1024.3 2283.32 992.982 2288.37 965.559 2292.95L964.567 2293.1L964.549 2293.1C953.171 2295 941.474 2286.65 939.415 2274.26C937.359 2261.9 945.721 2250.19 958.092 2248.14"
        />
        <path
          className="cherry-shine"
          d="M1533.04 1816.77L1533.4 1816.42L1533.43 1816.39L1533.04 1816.74C1580.17 1771.9 1630.1 1724.15 1689.44 1693.27L1689.44 1693.27C1748.81 1662.4 1816.51 1648.96 1880.25 1636.16C1892.52 1633.69 1904.5 1641.65 1906.96 1653.96C1909.43 1666.26 1901.47 1678.21 1889.19 1680.7L1889.19 1680.7C1824.14 1693.75 1763.05 1706.2 1710.39 1733.57L1710.39 1733.57C1657.76 1760.97 1612.44 1803.88 1564.36 1849.64L1563.98 1850L1563.98 1850.03C1555.27 1858.32 1540.89 1857.96 1532.24 1848.87C1523.59 1839.8 1523.95 1825.42 1533.04 1816.77"
        />
        <path
          className="cherry-shine"
          d="M432.01 1407.96L432.01 1407.96C479.146 1363.09 529.067 1315.37 588.423 1284.49L588.423 1284.49C647.784 1253.62 715.472 1240.16 779.214 1227.35C791.51 1224.88 803.483 1232.85 805.953 1245.15C808.422 1257.45 800.458 1269.43 788.161 1271.89L788.161 1271.89C723.121 1284.94 662.03 1297.39 609.385 1324.79L609.385 1324.79C556.736 1352.16 511.421 1395.07 463.325 1440.86C454.244 1449.51 439.87 1449.15 431.223 1440.09C422.575 1430.99 422.929 1416.61 432.01 1407.96"
        />
      </svg>
    </div>
  );
}

export default Cherry;
