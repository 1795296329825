import "./MotivationView.css";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";

import OliverProfileImg from "./oliver_profile.png";
import SoniyaProfileImg from "./soniya_profile.jpg";
import SaumyaProfileImg from "./saumya_profile.jpg";

function MotivationView() {
  return (
    <div className="motivation-view anim-appear">
      <h1 className="section-title">Our Motivation</h1>

      <p className="slogan">By picky people, for picky people</p>
      <p>
        We are three picky people who decided to build picky.careers for picky
        people looking to find the careers of their dreams.
      </p>
      <p>
        With technological advances occurring at breakneck speed, career options
        are exploding.
      </p>
      <p>
        And our longer healthful lives mean that multiple careers over a
        lifetime are no longer the exception.
      </p>
      <p>
        Consequently, the question “What do you want to be when you grow up?” is
        becoming increasingly relevant not just for children but for adults too.
      </p>
      <p>
        In just three short paragraphs, Picky.careers shows the good, bad, and
        beautiful about a career and whether it is right for you.
      </p>
      <p>
        It is our conviction that everyone deserves to do what they love.
        Picky.careers aims to make that happen.
      </p>

      <div className="who-we-are">
        <div className="person">
          <div className="photo">
            <img src={SaumyaProfileImg} />
          </div>
          <div className="info">
            <div className="name">Saumya Mitra</div>
            <div className="role">
              <WorkIcon fontSize="small" />
              <div>Macroeconomist, Oxford, UK</div>
            </div>
            <div className="location">
              <LocationOnIcon fontSize="small" />
              <div>Washington DC</div>
            </div>
          </div>
        </div>

        <div className="person">
          <div className="photo soniya-photo">
            <img src={SoniyaProfileImg} />
          </div>
          <div className="info">
            <div className="name">Soniya Carvalho</div>
            <div className="role">
              <WorkIcon fontSize="small" />
              <div>Development Economist, Cambridge, UK</div>
            </div>
            <div className="location">
              <LocationOnIcon fontSize="small" />
              <div>Washington DC</div>
            </div>
          </div>
        </div>

        <div className="person">
          <div className="photo">
            <img src={OliverProfileImg} />
          </div>
          <div className="info">
            <div className="name">Oliver Redeyoff</div>
            <div className="role">
              <WorkIcon fontSize="small" />
              <div>Software Developer</div>
            </div>
            <div className="location">
              <LocationOnIcon fontSize="small" />
              <div>Cambridge, UK</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MotivationView;
