import "./App.css";

import { useNavigate, useLocation } from "react-router-dom";

import Header from "components/misc/Header/Header";
import Greeting from "components/misc/Greeting/Greeting";
import AppView from "./components/views/AppView";

import { SignedIn } from "@clerk/clerk-react";
import { ClerkProvider } from "@clerk/clerk-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c02032",
    },
    secondary: {
      main: "#7A925E",
    },
  },
});

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <ClerkProvider
        publishableKey={clerkPubKey}
        navigate={(to) => navigate(to)}
        appearance={{
          variables: {
            colorPrimary: "#c02032",
          },
        }}
      >
        <Header />

        <div className="app-body">
          {/* Removed greeting but might add back later */}
          {/* <SignedIn>{location.pathname != "/" && <Greeting />}</SignedIn> */}
          <AppView />
        </div>
      </ClerkProvider>
    </ThemeProvider>
  );
}

export default App;
