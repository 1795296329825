import "./ContactView.css";

import TextField from "@mui/material/TextField";

import { useEffect, useState } from "react";

interface ContactForm {
  first_name: string;
  last_name: string;
  message: string;
}

function ContactView() {
  const [contactForm, setContactForm] = useState<ContactForm>(
    {} as ContactForm
  );
  const [contactFormErrors, setContactFormErrors] = useState<ContactForm>(
    {} as ContactForm
  );

  function send() {
    console.log(contactForm);
    setContactFormErrors({} as ContactForm);
    var errors: ContactForm = {} as ContactForm;
    if (!contactForm.first_name || contactForm.first_name == "") {
      errors.first_name = "Required";
    }

    if (!contactForm.last_name || contactForm.last_name == "") {
      errors.last_name = "Required";
    }

    if (!contactForm.message || contactForm.message == "") {
      errors.message = "Required";
    }

    console.log(errors);
    if (Object.keys(errors).length != 0) {
      setContactFormErrors(errors);
      console.log(contactFormErrors);
      return;
    }

    var subject =
      "Message from " + contactForm.first_name + " " + contactForm.last_name;
    window.open(
      "mailto:contact@picky.careers?subject=" +
        subject +
        "&body=" +
        contactForm.message
    );
  }

  return (
    <div className="contact-view anim-appear">
      <h1 className="section-title">Contact</h1>

      <p>Please fill out the form below or email us at contact@picky.careers</p>

      <div className="contact-form">
        <div className="name">
          <TextField
            fullWidth
            error={"first_name" in contactFormErrors}
            helperText={contactFormErrors.first_name}
            label="First Name"
            variant="outlined"
            onChange={(e) => {
              setContactForm({ ...contactForm, first_name: e.target.value });
            }}
          />
          <TextField
            fullWidth
            error={"last_name" in contactFormErrors}
            helperText={contactFormErrors.last_name}
            label="Last Name"
            variant="outlined"
            onChange={(e) => {
              setContactForm({ ...contactForm, last_name: e.target.value });
            }}
          />
        </div>

        <TextField
          fullWidth
          multiline
          error={"message" in contactFormErrors}
          helperText={contactFormErrors.message}
          label="Message"
          variant="outlined"
          rows={4}
          onChange={(e) => {
            setContactForm({ ...contactForm, message: e.target.value });
          }}
        />
      </div>

      <div className="button big" onClick={send}>
        Send
      </div>
    </div>
  );
}

export default ContactView;
