import "./UserCareerExperienceCard.css";

import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useUser } from "@clerk/clerk-react";

import Loader from "components/misc/Loader/Loader";

import {
  Career,
  CareerLength,
  UserCareerExperience,
  CareerExperienceForm,
} from "services/datatypes";
import api from "services/api";

function UserCareerExperienceCard(props: {
  initialState: "add" | "view" | "edit";
  userCareerExperience?: UserCareerExperience;
  addCareerCallback?: Function;
}) {
  const { isSignedIn } = useUser();

  const [state, setState] = useState<"add" | "view" | "edit" | null>(null);

  const [careerExperience, setCareerExperience] =
    useState<UserCareerExperience>({} as UserCareerExperience);
  const [careerExperienceForm, setCareerExperienceForm] =
    useState<CareerExperienceForm>({} as CareerExperienceForm);
  const [careerExperienceFormErrors, setCareerExperienceFormErrors] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [submitExperienceFormLoading, setSubmitExperienceFormLoading] =
    useState(false);

  const [careers, setCareers] = useState<Array<Career>>([]);
  const [secondaryCareers, setSecondaryCareers] = useState<Array<Career>>([]);
  const [careerLengths, setCareerLengths] = useState<Array<CareerLength>>([]);

  useEffect(() => {
    console.log("here");
    setCareerExperience(
      props.userCareerExperience ?? ({} as UserCareerExperience)
    );
    setState(props.initialState);

    if (isSignedIn == true) {
      api.getCareers().then((res) => {
        setCareers(res);
      });

      api.getCareerLengths().then((res) => {
        setCareerLengths(res);
      });
    }
  }, []);

  useEffect(() => {
    if (careerExperienceForm.primary_career_name) {
      api.getCareers(careerExperienceForm.primary_career_name).then((res) => {
        setSecondaryCareers(res);
      });
    }
  }, [careerExperienceForm.primary_career_name]);

  function aoran(nextWord: string) {
    let c = nextWord.charAt(0).toLowerCase();
    if (c == "a" || c == "e" || c == "i" || c == "o" || c == "u") {
      return "an " + nextWord;
    } else {
      return "a " + nextWord;
    }
  }

  function getQuestions() {
    const careerName = aoran(
      careerExperience.secondary_career?.name ??
        careerExperience.primary_career.name
    );

    return [
      `What do you love about being ${careerName}?`,
      `What do you not love about being ${careerName}?`,
      `What key skills and qualities do you need to thrive as ${careerName}?`,
    ];
  }

  function getFormQuestions() {
    const careerName = aoran(
      careerExperienceForm.secondary_career_name ??
        careerExperienceForm.primary_career_name ??
        "..."
    );

    return [
      `What do you love about being ${careerName}?`,
      `What do you not love about being ${careerName}?`,
      `What key skills and qualities do you need to thrive as ${careerName}?`,
    ];
  }

  function capitaliseEachWord(value: string) {
    // capitalise each word
    const words = value.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i] == "") {
        continue;
      }
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  function hasErrors(key: string) {
    return careerExperienceFormErrors.hasOwnProperty(key);
  }

  function getErrorMessage(key: string) {
    if (!careerExperienceFormErrors.hasOwnProperty(key)) {
      return "";
    } else {
      return careerExperienceFormErrors[key][0];
    }
  }

  function startEdit() {
    // prepopulate form with existing data
    console.log(careerExperience);
    setCareerExperienceForm({
      id: careerExperience.id,
      primary_career_name: careerExperience.primary_career.name,
      secondary_career_name: careerExperience?.secondary_career?.name ?? null,
      description: careerExperience.description,
      career_length: careerExperience.career_length.id,
      question_1: careerExperience.question_1,
      question_2: careerExperience.question_2,
      question_3: careerExperience.question_3,
    });

    setState("edit");
  }

  function submitUpdate() {
    setSubmitExperienceFormLoading(true);
    api
      .putCurrentUserCareerExperience(careerExperienceForm)
      .then((res) => {
        // should update careerExperience
        console.log(res);
        console.log(careerExperience);
        setCareerExperience(res);
        setCareerExperienceFormErrors({});
        setState("view");
      })
      .catch((err) => {
        setCareerExperienceFormErrors(err.response.data);
      })
      .finally(() => {
        setSubmitExperienceFormLoading(false);
      });
  }

  function submitAdd() {
    setSubmitExperienceFormLoading(true);
    api
      .postCurrentUserCareerExperience(careerExperienceForm)
      .then((res) => {
        // should trigger reload in parent
        // loadCareerExperiences();
        setCareerExperienceForm({} as CareerExperienceForm);
        setCareerExperienceFormErrors({});
      })
      .catch((err) => {
        setCareerExperienceFormErrors(err.response.data);
      })
      .finally(() => {
        setSubmitExperienceFormLoading(false);
        if (props.addCareerCallback) {
          props.addCareerCallback();
        }
      });
  }

  return (
    <div className="user-career-experience anim-appear">
      {(state == "add" || state == "edit") && (
        <div className="career-experience-form">
          <div className="add-career-blocks">
            <div className="add-career-block">
              <div className="label">
                Career<span className="required">*</span>
              </div>
              <Autocomplete
                freeSolo
                forcePopupIcon
                disableClearable
                size="small"
                value={careerExperienceForm.primary_career_name ?? ""}
                options={careers.map((career) => {
                  return career.name;
                })}
                onChange={(_, newValue) => {
                  setCareerExperienceForm({
                    ...careerExperienceForm,
                    primary_career_name: newValue || "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select or type"
                    error={hasErrors("primary_career")}
                    helperText={getErrorMessage("primary_career")}
                    onChange={(e) => {
                      setCareerExperienceForm({
                        ...careerExperienceForm,
                        primary_career_name: capitaliseEachWord(e.target.value),
                      });
                    }}
                  />
                )}
              />
            </div>

            <div className="add-career-block">
              <div className="label">Career branch</div>
              <Autocomplete
                freeSolo
                forcePopupIcon
                disableClearable
                size="small"
                value={careerExperienceForm.secondary_career_name ?? ""}
                options={secondaryCareers.map((secondaryCareer) => {
                  return secondaryCareer.name;
                })}
                onChange={(_, newValue) => {
                  setCareerExperienceForm({
                    ...careerExperienceForm,
                    secondary_career_name: newValue || "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select or type"
                    error={hasErrors("secondary_career")}
                    helperText={getErrorMessage("secondary_career")}
                    onChange={(e) => {
                      setCareerExperienceForm({
                        ...careerExperienceForm,
                        secondary_career_name: capitaliseEachWord(
                          e.target.value
                        ),
                      });
                    }}
                  />
                )}
              />
            </div>

            <div className="add-career-block">
              <div className="label">
                Years of experience<span className="required">*</span>
              </div>
              <Select
                displayEmpty
                fullWidth
                error={hasErrors("career_length")}
                size="small"
                value={careerExperienceForm.career_length || ""}
                onChange={(e) => {
                  setCareerExperienceForm({
                    ...careerExperienceForm,
                    career_length: Number(e.target.value),
                  });
                }}
              >
                <MenuItem value={careerExperienceForm.career_length ?? ""}>
                  <em>
                    <span className="helper-text">Select</span>
                  </em>
                </MenuItem>
                {careerLengths.map((careerLength) => (
                  <MenuItem key={careerLength.id} value={careerLength.id}>
                    {careerLength.text}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {getErrorMessage("career_length")}
              </FormHelperText>
            </div>
          </div>

          <div className="add-career-description">
            <div className="label">Short description</div>
            <TextField
              multiline
              fullWidth
              value={careerExperienceForm.description ?? ""}
              error={hasErrors("description")}
              helperText={getErrorMessage("description")}
              variant="outlined"
              rows={2}
              onChange={(e) => {
                setCareerExperienceForm({
                  ...careerExperienceForm,
                  description: e.target.value,
                });
              }}
            />
          </div>

          <div className="add-career-questions">
            <div className="add-career-question">
              <div className="label">
                {getFormQuestions()[0]}
                <span className="required">*</span>
              </div>
              <TextField
                multiline
                fullWidth
                value={careerExperienceForm.question_1 ?? ""}
                error={hasErrors("question_1")}
                helperText={getErrorMessage("question_1")}
                variant="outlined"
                rows={4}
                onChange={(e) => {
                  setCareerExperienceForm({
                    ...careerExperienceForm,
                    question_1: e.target.value,
                  });
                }}
              />
            </div>
            <div className="add-career-question">
              <div className="label">
                {getFormQuestions()[1]}
                <span className="required">*</span>
              </div>
              <TextField
                multiline
                fullWidth
                value={careerExperienceForm.question_2 ?? ""}
                error={hasErrors("question_2")}
                helperText={getErrorMessage("question_2")}
                variant="outlined"
                rows={4}
                onChange={(e) => {
                  setCareerExperienceForm({
                    ...careerExperienceForm,
                    question_2: e.target.value,
                  });
                }}
              />
            </div>
            <div className="add-career-question">
              <div className="label">
                {getFormQuestions()[2]}
                <span className="required">*</span>
              </div>
              <TextField
                multiline
                fullWidth
                value={careerExperienceForm.question_3 ?? ""}
                error={hasErrors("question_3")}
                helperText={getErrorMessage("question_3")}
                variant="outlined"
                rows={4}
                onChange={(e) => {
                  setCareerExperienceForm({
                    ...careerExperienceForm,
                    question_3: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="form-footer">
            {state == "add" && (
              <div
                className={
                  "button" +
                  (submitExperienceFormLoading == true ? " disabled" : "")
                }
                onClick={submitAdd}
              >
                {submitExperienceFormLoading == true ? <Loader /> : <>Submit</>}
              </div>
            )}

            {state == "edit" && (
              <>
                <div
                  className={
                    "button" +
                    (submitExperienceFormLoading == true ? " disabled" : "")
                  }
                  style={{ marginRight: "20px" }}
                  onClick={submitUpdate}
                >
                  {submitExperienceFormLoading == true ? (
                    <Loader />
                  ) : (
                    <>Update</>
                  )}
                </div>
                <div
                  className={
                    "button" +
                    (submitExperienceFormLoading == true ? " disabled" : "")
                  }
                  onClick={() => {
                    setState("view");
                  }}
                >
                  Cancel
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {state == "view" && careerExperience && (
        <>
          <div className="career-info">
            <div
              className="edit"
              onClick={() => {
                startEdit();
              }}
            >
              <ModeEditIcon />
            </div>

            <div className="career-name">
              {careerExperience.primary_career.name}
              {careerExperience.secondary_career != null
                ? " (" + careerExperience.secondary_career.name + ")"
                : ""}
            </div>
            <div className="career-length">
              {careerExperience.career_length.text}
            </div>
            <div className="career-description">
              {careerExperience.description}
            </div>
          </div>

          <div className="questions">
            <div className="question">
              <div className="question-title">{getQuestions()[0]}</div>
              <div className="question-answer">
                {careerExperience.question_1.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </div>
            </div>

            <div className="question">
              <div className="question-title">{getQuestions()[1]}</div>
              <div className="question-answer">
                {careerExperience.question_2.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </div>
            </div>

            <div className="question">
              <div className="question-title">{getQuestions()[2]}</div>
              <div className="question-answer">
                {careerExperience.question_3.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserCareerExperienceCard;
