import { Routes, Route, Navigate } from "react-router-dom";

import ExploreSearchView from "components/views/ExploreView/ExploreSearchView/ExploreSearchView";
import ExploreCareerView from "components/views/ExploreView/ExploreCareerView/ExploreCareerView";

import routes from "services/routes";

function ExploreView() {
  return (
    <div className="explore-view">
      <Routes>
        <Route path="/" element={<ExploreSearchView />} />

        <Route path="/:careerName" element={<ExploreCareerView />} />
      </Routes>
    </div>
  );
}

export default ExploreView;
