import "./CareerExperienceCard.css";

import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import LinkIcon from '@mui/icons-material/Link';
import Swal from 'sweetalert2';

import Loader from "../Loader/Loader";

import { SignedIn, useUser } from "@clerk/clerk-react";

import api from "services/api";
import {
  CareerExperience,
  CareerExperienceComment,
  CareerExperienceCommentForm,
} from "services/datatypes";
import { useNavigate } from "react-router-dom";
import routes from "services/routes";

const GPTMaleUserIds = [
  "user_2Qz4Gjo10e2lwStVR9g8jzbBHPI", // Oliver Redeyoff (dev environment)
  "user_2YIjKvWSv8WGLCJCJJn3GqVxppi", // Warren Buffett
  "user_2YIiW77x9K4xLhhyYaNRk06eR1V", // Bill Gates
];
const GPTFemaleUserIds = [
  "user_2YItlLbN99EkVBTu4DOyPwnoCQR", // Indra Nooyi
];

function CareerExperienceCard(props: {
  careerExperience: CareerExperience;
  showCareerName: boolean;
}) {
  const navigate = useNavigate()
  const { user, isSignedIn } = useUser();
  const [learnMoreUrl, setLearnMoreUrl] = useState("")
  const [hasLiked, setHasLiked] = useState<boolean | null>(null);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [commentsLoading, setCommentsLoading] = useState<boolean | null>(null);
  const [comments, setComments] = useState<Array<CareerExperienceComment>>([]);
  const [commentForm, setCommentForm] = useState<CareerExperienceCommentForm>(
    {} as CareerExperienceCommentForm
  );
  const [commentFormError, setCommentFormError] = useState<string | null>(null);
  const [submitCommentLoading, setSubmitCommentLoading] =
    useState<boolean>(false);    

  useEffect(() => {
    api.getUserInformation(props.careerExperience.user_id).then((res) => {
      setLearnMoreUrl(res.learn_more_url || "")
    })
  }, [])

  useEffect(() => {
    if (user != null) {
      // see if user has liked career experience
      var userHasLiked = false;
      for (const userId of props.careerExperience.likes) {
        if (userId == user.id) {
          userHasLiked = true;
        }
      }
      setHasLiked(userHasLiked);
    }
  }, [user]);

  function aoran(nextWord: string) {
    let c = nextWord.charAt(0).toLowerCase();
    if (c == "a" || c == "e" || c == "i" || c == "o" || c == "u") {
      return "an " + nextWord;
    } else {
      return "a " + nextWord;
    }
  }

  function getQuestions() {
    var isMaleGPT = GPTMaleUserIds.includes(props.careerExperience.user_id);
    var isFemaleGPT = GPTFemaleUserIds.includes(props.careerExperience.user_id);

    // return normal questions if they are not GPT
    if (!isMaleGPT && !isFemaleGPT) {
      const careerName = aoran(
        props.careerExperience.secondary_career?.name ??
          props.careerExperience.primary_career.name
      );

      return [
        `What do you love about being ${careerName}?`,
        `What do you not love about being ${careerName}?`,
        `What key skills and qualities do you need to thrive as ${careerName}?`,
      ];
    }

    // return custom question if they are GPT
    const fullName = `${props.careerExperience.clerk_user.first_name} ${props.careerExperience.clerk_user.last_name}`;
    const pronoun = isMaleGPT ? "he" : "she";
    const determiner = isMaleGPT ? "his" : "her";

    return [
      `What has ${fullName} said ${pronoun} loves about ${determiner} career?`,
      `What has ${fullName} said ${pronoun} does not love about ${determiner} career?`,
      `What has ${fullName} said are the skills and qualities needed to thrive in ${determiner} career?`,
    ];
  }

  function toogleShowComments() {
    if (showComments == false) {
      loadComments();
      setShowComments(true);
    } else {
      setShowComments(false);
    }
  }

  function toggleLikeCareerExperience() {
    if (isSignedIn === false) {
      Swal.fire({
        title: 'Sign In',
        text: 'Please sign in to like this post',
        icon: 'info',
        confirmButtonText: 'Sign In',
        showCancelButton: true,
        cancelButtonText: "Cancel",
      }).then((value) => {
        if (value.isConfirmed) {
          navigate(routes.authenticate.short+`?afterSignInUrl=${window.location.href}`)
        }
      })
    }

    if (user == null) {
      return;
    }

    if (hasLiked == false) {
      api.likeCareerExperience(props.careerExperience.id).then((res) => {
        setHasLiked(true);
        props.careerExperience.likes.push(user.id);
      });
    } else if (hasLiked == true) {
      api.unlikeCareerExperience(props.careerExperience.id).then((res) => {
        setHasLiked(false);
        props.careerExperience.likes = props.careerExperience.likes.filter(
          (el) => {
            return el != user.id;
          }
        );
      });
    }
  }

  function loadComments() {
    setCommentsLoading(true);
    setComments([]);
    api.getCareerExperienceComment(props.careerExperience.id).then((res) => {
      setComments(res);
      setCommentsLoading(false);
    });
  }

  function submitNewComment() {
    if (commentForm.text) {
      setCommentFormError(null);
      setSubmitCommentLoading(true);
      commentForm.career_experience = props.careerExperience.id;
      api
        .postCareerExperienceComment(commentForm)
        .then((res) => {
          api
            .getCareerExperienceComment(props.careerExperience.id)
            .then((res) => {
              setComments(res);
              setCommentForm({ ...commentForm, text: "" });
              setSubmitCommentLoading(false);
            });
        })
        .catch((res) => {
          const data = res.response.data;
          if (typeof data == "object") {
            if ("text" in data) {
              setCommentFormError(data["text"]);
            }
          }
        });
    }
  }

  return (
    <div className="career-experience anim-appear">
      <div className="user-info">
        {props.showCareerName == false && (
          <div
            className="profile-image"
            style={{
              backgroundImage: `url(${props.careerExperience.clerk_user.profile_image_url})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
        )}

        <div className="details">
          <div className="user-name">
            <span>
              {props.showCareerName == false && (
                <>
                  {props.careerExperience.clerk_user.first_name}{" "}
                  {props.careerExperience.clerk_user.last_name}{" "}
                </>
              )}
              {props.showCareerName == true && (
                <>{props.careerExperience.primary_career.name}</>
              )}
              {props.careerExperience.secondary_career != null ? (
                <>
                  {" "}
                  -
                  {" "}
                  <span className="secondary-career-name">
                    {props.careerExperience.secondary_career.name}
                  </span>
                </>
              ) : (
                ""
              )}
            </span>

            {props.showCareerName == false && learnMoreUrl && (
              <a className="anim-appear" href={learnMoreUrl} target="_blank">
                <LinkIcon />
                View Profile
                </a>
            )}
          </div>
          
          <div className="career-length">
            <span className="duration">
              {props.careerExperience.career_length.text}
            </span>
          </div>
        </div>

        <div className="flex-filler"></div>

          {(isSignedIn == false || hasLiked != null) && (
            <div className="button-2" onClick={toggleLikeCareerExperience}>
              <div className="button-2-inner">
                {(isSignedIn == false || hasLiked == false) ? <ThumbUpOffAltIcon /> : <ThumbUpIcon />}
                <SignedIn><div>{props.careerExperience.likes.length}</div></SignedIn>
              </div>
            </div>
          )}
      </div>

      <div className="description">{props.careerExperience.description}</div>

      <div className="questions">
        <div className="question">
          <div className="question-title">{getQuestions()[0]}</div>
          <div className="question-answer">
            {props.careerExperience.question_1.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </div>
        </div>

        <div className="question">
          <div className="question-title">{getQuestions()[1]}</div>
          <div className="question-answer">
            {props.careerExperience.question_2.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </div>
        </div>

        <div className="question">
          <div className="question-title">{getQuestions()[2]}</div>
          <div className="question-answer">
            {props.careerExperience.question_3.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </div>
        </div>
      </div>

      <div className="comments">
        <div>
          <div className="button-2" onClick={toogleShowComments}>
            <div className="button-2-inner">
              <ChatIcon />
              <div>{showComments == false ? "View" : "Hide"} comments</div>
            </div>
          </div>
        </div>

        {showComments && (
          <div className="comments-inner anim-appear">
            <SignedIn>
              <div className="new-comment">
                <TextField
                  multiline
                  fullWidth
                  rows={2}
                  value={commentForm.text}
                  className="career-name-search"
                  placeholder="New comment"
                  variant="outlined"
                  size="small"
                  error={commentFormError != null}
                  helperText={commentFormError}
                  onChange={(e) => {
                    setCommentForm({ ...commentForm, text: e.target.value });
                  }}
                />

                <div
                  className={
                    "button-2" +
                    (submitCommentLoading == false ? "" : " disabled")
                  }
                  onClick={submitNewComment}
                >
                  <div className="button-2-inner">
                    {submitCommentLoading == false ? <SendIcon /> : <Loader />}
                  </div>
                </div>
              </div>
            </SignedIn>

            {commentsLoading == true && (
              <>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <Loader />
                </div>
              </>
            )}

            {commentsLoading == false && (
              <>
                {comments.length == 0 && (
                  <div style={{ textAlign: "center", marginTop: "30px" }}>
                    No comments
                  </div>
                )}

                {comments.length > 0 && (
                  <div className="comments-list">
                    {comments.map((comment) => (
                      <div key={comment.id} className="comment anim-appear">
                        <div
                          className="user-image"
                          style={{
                            backgroundImage: `url(${comment.clerk_user.profile_image_url})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        ></div>
                        <div className="comment-content">
                          <div className="user-name">
                            {comment.clerk_user.first_name}{" "}
                            {comment.clerk_user.last_name}
                          </div>
                          <div className="comment-text">{comment.text}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CareerExperienceCard;
