import "./UserAnswers.css";

import Loader from "components/misc/Loader/Loader";
import CareerExperienceCard from "components/misc/CareerExperienceCard/CareerExperienceCard";
import LinkIcon from '@mui/icons-material/Link';

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import api from "services/api";
import { ClerkUser, CareerExperience } from "services/datatypes";

function UserAnswersView() {
  let { userId } = useParams();
  const [user, setUser] = useState<ClerkUser | null>(null);
  const [learnMoreUrl, setLearnMoreUrl] = useState("test");
  const [careerExperiencesLoading, setCareerExperiencesLoading] =
    useState(false);
  const [careerExperiences, setCareerExperiences] = useState<
    Array<CareerExperience>
  >([]);

  useEffect(() => {
    if (userId) {
      setCareerExperiencesLoading(true);

      api
        .getClerkUser(userId)
        .then((res) => {
          setUser(res);
        })
        .catch(() => {});

      api
        .getUserInformation(userId)
        .then((res) => {
          setLearnMoreUrl(res.learn_more_url || "")
        })
        .catch(() => {})

      api
        .getUserCareerExperiences(userId)
        .then((res) => {
          setCareerExperiences(res);
        })
        .catch(() => {})
        .finally(() => {
          setCareerExperiencesLoading(false);
        });
    }
  }, []);

  return (
    <div className="user-answers-view">
      {user != null && (
        <div className="page-title">
          <div
            className="profile-image"
            style={{
              backgroundImage: `url(${user.profile_image_url})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <h1>
            {user.first_name} {user.last_name}
          </h1>
          {learnMoreUrl && (
            <a className="anim-appear" href={learnMoreUrl} target="_blank">
              <LinkIcon />
              View Profile
              </a>
          )}
        </div>
      )}

      {careerExperiencesLoading == true && (
        <>
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            <Loader />
          </div>
        </>
      )}

      {careerExperiencesLoading == false && (
        <>
          {careerExperiences.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "60px" }}>
              No answers for this career yet
            </div>
          )}

          {careerExperiences.map((careerExperience) => (
            <CareerExperienceCard
              key={careerExperience.id}
              careerExperience={careerExperience}
              showCareerName={true}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default UserAnswersView;
